<template>
  <nav class="flex justify-center space-x-4">
    <Item
      v-for="item in items"
      :key="item.name"
      :item="item"
    />
    <StubItem
      v-for="item in stubItems"
      :key="item.name"
      :stub-item="item"
    />
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Item from './Item.vue'
import StubItem from './StubItem.vue'
import { Route } from '~/modules/shared/types.ts'
import type { MenuItem, MenuStubItem } from '~/modules/shared/types.ts'
import { useEventStore } from '~/modules/event/store/event.ts'

const items = computed<MenuItem[]>(() => {
  return [
    {
      name: 'Моніторинг',
      route: {
        name: Route.DEVICE_MAP,
      },
    },
    {
      name: 'Аналітика',
      route: {
        name: Route.DEVICES_ANALYTICS_LIST,
      },
      relatedRoutes: [Route.DEVICE_ANALYTICS_GRAPH],
    },
    {
      name: 'Налаштування',
      route: {
        name: Route.DEVICES_SETTINGS_LIST,
      },
      relatedRoutes: [
        Route.DEVICE_GENERAL_SETTINGS,
        Route.DEVICE_COMMAND_SETTINGS,
      ],
    },
    {
      name: 'Події',
      route: {
        name: Route.EVENT_LIST,
      },
      count: useEventStore().unreadEventsCount,
    },
  ]
})

const stubItems: MenuStubItem[] = [
  {
    name: 'Is simply',
  },
]
</script>
