<template>
  <NotificationGroup>
    <div class="fixed right-[25px] top-[25px] z-10">
      <div class="w-md w-full">
        <Notification
          v-slot="{ notifications, close }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="mt-[10px] flex rounded border bg-white shadow first-of-type:mt-0"
          >
            <span class="hidden">{{
              addCloser(() => close(notification.id))
            }}</span>
            <div class="flex w-1 items-center justify-center bg-red-400" />
            <div class="flex w-full flex-col p-4">
              <div class="flex justify-between">
                <span class="font-medium text-red-500">Нова подія!</span>
                <CloseIcon
                  class="aspect-square w-[11px] cursor-pointer fill-current text-gray-500 hover:text-gray-700"
                  @click="
                    readEvent(notification.eventId);
                    close(notification.id);
                  "
                />
              </div>
              <span
                class="pt-2 text-sm text-gray-600"
                v-html="notification.message"
              />
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>

<script lang="ts" setup>
import {
  createNotifier,
  defineNotificationComponent,
  NotificationGroup,
} from 'notiwind'
import { watch } from 'vue'
import type { EventDto, ReadEventDto } from 'core'
import DOMPurify from 'dompurify'
import { useApi } from '~/modules/shared/composables/useApi.ts'
import { Route } from '~/modules/shared/types.ts'
import { useRoute } from '#imports'
import { useEventStore } from '~/modules/event/store/event.ts'
import { useEventBus } from '~/modules/shared/composables/useEventBus.ts'
import { eventConfigs } from '~/modules/event/constants.ts'
import CloseIcon from '~/modules/shared/ui/components/Icon/CloseIcon.vue'

type NotificationSchema = {
  eventId: string
  message: string
}

const notify = createNotifier<NotificationSchema>()
const Notification = defineNotificationComponent<NotificationSchema>()

const eventStore = useEventStore()

const readEvent = (eventId: string) => {
  eventStore.setUnreadEventsCount(--eventStore.unreadEventsCount)

  useApi().post('/event/read', {
    id: eventId,
  } as ReadEventDto)
}

useEventBus().on('eventCreated', (event: EventDto) => {
  notify(
    {
      eventId: event.id,
      message: DOMPurify.sanitize(eventConfigs[event.type].notify(event)),
    },
    -1,
  )
})

type Closer = () => void

let closers: Closer[] = []

const addCloser = (closer: Closer): void => {
  closers.push(closer)
}

watch(useRoute(), (route): void => {
  if (Route.EVENT_LIST === route.name) {
    closers.forEach(callback => callback())

    closers = []
  }
})
</script>
