<template>
  <Menu>
    <MenuButton
      class="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100"
    >
      <span class="font-medium text-gray-600">{{ userInitial }}</span>
      <span
        class="absolute left-7 top-0 h-3.5 w-3.5 rounded-full border-2 border-white bg-green-400"
      />
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="px-1 py-1">
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                'group flex w-full items-center rounded-md px-2 py-2 text-sm',
              ]"
              @click="onLogout"
            >
              Вийти з акаунта
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { useApi } from '~/modules/shared/composables/useApi'
import { useUserStore } from '~/modules/auth/stores/user'

const userInitial: string = useUserStore().user!.email.charAt(0).toUpperCase()

const onLogout = (): void => {
  useApi()
    .post('/auth/logout')
    .then(() => useUserStore().resetUser())
}
</script>
